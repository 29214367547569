<!-- @format -->

<template>
  <div>
    <div
      role="status"
      class="animate-pulse bg-white py-5"
      v-if="loading && isView"
    >
      <div
        class="flex items-center justify-center my-4"
        v-for="key in 2"
        :key="key"
      >
        <div class="w-full">
          <div
            class="w-20 h-2.5 mx-4 my-2 bg-gray-50 rounded-full dark:bg-gray-400 me-3"
          ></div>
          <div
            class="w-full h-2.5 mx-4 py-4 bg-gray-50 rounded dark:bg-gray-400 me-3"
          ></div>
        </div>
        <div class="w-full mx-4">
          <div
            class="w-20 h-2.5 my-2 mx-4 bg-gray-50 rounded-full dark:bg-gray-400 me-3"
          ></div>
          <div
            class="w-full h-2.5 mx-4 py-4 bg-gray-50 rounded dark:bg-gray-400 me-3"
          ></div>
        </div>
      </div>
      <div
        class="w-20 h-2.5 my-2 mx-4 bg-gray-50 rounded-full dark:bg-gray-400 me-3"
      ></div>
      <div
        class="h-2.5 bg-gray-50 rounded py-32 mx-4 dark:bg-gray-400 max-w-[640px] mb-2.5"
      ></div>
      <div
        class="w-20 h-2.5 my-2 mx-4 bg-gray-50 rounded-full dark:bg-gray-400 me-3"
      ></div>
      <div
        class="h-2.5 bg-gray-50 rounded py-4 mx-4 dark:bg-gray-400 max-w-[540px]"
      ></div>
    </div>

    <div v-else class="bg-white rounded-md p-5">
      <div class="text-2xl mt-5">{{ pageTitle }}</div>
      <div class="flex mt-5 gap-8">
        <div class="w-1/2 flex flex-col gap-2">
          <label class="text-sm">Patient NHS Number</label>
          <input
            type="number"
            v-model="patientNHSNumber"
            min="0"
            placeholder="NHS Number"
            @change="errors.patientNHSNumber = ''"
            :disabled="isView"
            class="border border-gray-200 p-2 rounded"
            :class="isView ? 'isEdit' : ''"
          />
          <small
            v-if="!isValidNumber(patientNHSNumber) && !errors.patientNHSNumber"
            class="text-red-500"
            >Decimal Values Cannot be added</small
          >
          <span v-if="errors.patientNHSNumber" class="text-red-500 text-xs">{{
            errors.patientNHSNumber
          }}</span>
        </div>
        <div class="w-1/2 flex flex-col gap-2">
          <label class="text-sm">Source of Compliment</label>
          <input
            type="text"
            v-model="sourceOfCompliment"
            @change="errors.sourceOfCompliment = ''"
            :maxlength="50"
            class="border border-gray-200 p-2 rounded"
            :disabled="isView"
            :class="isView ? 'isEdit' : ''"
          />
          <span v-if="errors.sourceOfCompliment" class="text-red-500 text-xs">{{
            errors.sourceOfCompliment
          }}</span>
        </div>
      </div>
      <div class="flex mt-5 gap-8">
        <div class="w-full flex flex-col gap-2">
          <label class="text-sm">Recepient of Compliment</label>
          <input
            type="text"
            v-model="forCompliment"
            @change="errors.forCompliment = ''"
            :maxlength="50"
            :disabled="isView"
            class="border border-gray-200 p-2 rounded"
            :class="isView ? 'isEdit' : ''"
          />
          <span v-if="errors.forCompliment" class="text-red-500 text-xs">{{
            errors.forCompliment
          }}</span>
        </div>
        <div class="w-full flex flex-col gap-2" v-if="!isManager">
          <label class="text-sm">{{ practiceTitle }}</label>
          <v-select
            v-if="!isView"
            label="practice_name"
            placeholder="Select Practice"
            :options="practices"
            @update:modelValue="selectedPractice"
          ></v-select>
          <div v-else class="border border-gray-200 py-2 px-2 rounded isEdit">
            {{ practices }}
          </div>
          <span v-if="errors.selectedPracticeId" class="text-red-500 text-xs">{{
            errors.selectedPracticeId
          }}</span>
        </div>
      </div>
      <div class="flex flex-col mt-5 gap-2">
        <label class="text-sm">Compliment</label>
        <textarea
          v-model="complimentDetails"
          type="text"
          :maxlength="1000"
          :disabled="isView"
          class="border border-gray-200 p-2 rounded"
          placeholder="Write Details Here"
          :class="isView ? 'isEdit h-44' : ''"
          @change="errors.complimentDetails = ''"
          @input="adjustHeight"
          ref="textarea"
        ></textarea>
        <span v-if="errors.complimentDetails" class="text-red-500 text-xs">{{
          errors.complimentDetails
        }}</span>
        <small v-if="!isView">Your Details between 0-1000 Characters</small>
      </div>
      <div class="flex flex-col mt-5 gap-2">
        <label class="text-sm">Receiving Date</label>
        <div
          class="border border-gray-200 rounded pl-2 p-2 flex justify-between"
          :class="
            isView ? 'isEdit' : currentDate ? 'text-black' : 'text-gray-400'
          "
          :style="{ pointerEvents: isView ? 'none' : 'auto' }"
        >
          {{
            moment(currentDate).format("MMM DD, YYYY") === "Invalid date"
              ? "MMM DD, YYYY"
              : moment(currentDate).format("MMM DD, YYYY")
          }}
          <input
            type="date"
            @change="errors.currentDate = ''"
            :max="formatDate(date)"
            v-model="currentDate"
            class="rounded text-white focus:outline-none"
            :class="isView ? 'isEdit' : ''"
          />
        </div>
        <span v-if="errors.currentDate" class="text-red-500 text-xs">{{
          errors.currentDate
        }}</span>
      </div>
      <div class="flex justify-between my-5" v-if="!isView">
        <button
          class="border-teal border py-3 px-10 rounded-md text-teal"
          @click="router.go(-1)"
        >
          Cancel
        </button>
        <button
          class="py-3 px-10 bg-teal text-white rounded-md font-light"
          :class="loadingButton ? 'bg-opacity-10 cursor-not-allowed' : ''"
          @click="saveCompliment"
          :disabled="loadingButton"
        >
          {{ isManager ? "Done" : "Save" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import icon from "@/assets/icons/success.svg";
const store = useStore();
const route = useRoute();
const router = useRouter();
const isView = parseInt(route.params.view);
const isManager = ref(parseInt(route.params.isManager));
const complimentId = ref(parseInt(route.params.complimentId));
const loadingButton = ref(false);
const compliment = computed(() => {
  return store.getters["hqCompliments/getCompliment"];
});
const complimentMa = computed(() => {
  return store.getters["maCompliments/getCompliment"];
});
const pageTitle = computed(() => {
  return isView ? "Compliment Details" : "New Compliment";
});
const practiceTitle = computed(() => {
  return isView ? "Practice Name" : "Select Practice";
});

const patientNHSNumber = ref("");
const sourceOfCompliment = ref("");
const forCompliment = ref("");
const practices = ref("");
const complimentDetails = ref("");
const loading = ref(false);
const selectedPracticeId = ref("");
const currentDate = ref(null);
const errors = ref({
  patientNHSNumber: "",
  sourceOfCompliment: "",
  forCompliment: "",
  selectedPracticeId: "",
  complimentDetails: "",
  currentDate: "",
});

watch(
  isManager.value ? complimentMa : compliment,
  (newValue) => {
    if (newValue && isView) {
      patientNHSNumber.value = newValue.nhs_number || "";
      sourceOfCompliment.value = newValue.compliment_source || "";
      forCompliment.value = newValue.compliment_for || "";
      complimentDetails.value = newValue.compliment || "";
      practices.value = newValue.practice?.practice_name || "";
      currentDate.value = newValue.receiving_date || "";
    } else {
      patientNHSNumber.value = "";
      sourceOfCompliment.value = "";
      forCompliment.value = "";
      complimentDetails.value = "";
      selectedPracticeId.value = "";
    }
  },
  { immediate: true }
);

function isDecimal(num) {
  return num % 1;
}

const isValidNumber = (number) => {
  return !isDecimal(number);
};

const date = new Date();
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const selectedPractice = (practice) => {
  errors.value.selectedPracticeId = "";
  selectedPracticeId.value = practice?.id;
};

const saveCompliment = () => {
  let hasErrors = false;

  if (!patientNHSNumber.value) {
    errors.value.patientNHSNumber = "Patient NHS Number is required";
    hasErrors = true;
  } else {
    if (!isValidNumber(patientNHSNumber.value)) {
      errors.value.patientNHSNumber = "Decimal Type of Number cannot be added";
      hasErrors = true;
    } else if (patientNHSNumber.value < 0) {
      errors.value.patientNHSNumber = "NHS Number cannot be negative";
      hasErrors = true;
    } else {
      errors.value.patientNHSNumber = "";
    }
  }

  if (!sourceOfCompliment.value) {
    errors.value.sourceOfCompliment = "Source of Compliment is required";
    hasErrors = true;
  } else {
    errors.value.sourceOfCompliment = "";
  }

  if (!forCompliment.value) {
    errors.value.forCompliment = "For Compliment is required";
    hasErrors = true;
  } else {
    errors.value.forCompliment = "";
  }

  if (!selectedPracticeId.value && !isManager.value) {
    errors.value.selectedPracticeId = "Practice selection is required";
    hasErrors = true;
  } else {
    errors.value.selectedPracticeId = "";
  }

  if (!complimentDetails.value) {
    errors.value.complimentDetails = "Compliment details are required";
    hasErrors = true;
  } else {
    errors.value.complimentDetails = "";
  }
  if (!currentDate.value) {
    errors.value.currentDate = "Compliment date is required";
    hasErrors = true;
  } else {
    errors.value.currentDate = "";
  }

  if (hasErrors) {
    return;
  }

  const payload = {
    nhs_number: patientNHSNumber.value.toString(),
    compliment_source: sourceOfCompliment.value,
    compliment_for: forCompliment.value,
    practice: isManager.value
      ? localStorage.getItem("practice")
      : selectedPracticeId.value,
    compliment: complimentDetails.value,
    receiving_date: currentDate.value,
  };
  let endpoint;
  if (isManager.value) {
    endpoint = "maCompliments/postComplimentMa";
  } else {
    endpoint = "hqCompliments/postCompliment";
  }
  loadingButton.value = true;
  store
    .dispatch(endpoint, payload)
    .then(() => {
      loadingButton.value = false;
      Swal.fire({
        title: `Your Compliment Is Posted Successfully`,
        iconHtml: `<img src="${icon}" />`,
        customClass: {
          title: "custom-title",
          popup: "border-radius",
          content: "custom-text",
          icon: "icon-border",
        },
        timer: 2000,
        showConfirmButton: false,
        background: `rgba(225, 249, 240, 1)`,
      });
      router.push({
        name: isManager.value
          ? "ma-monitoringOfPracticeFunctionComplimentDashboard"
          : "hq-monitoringOfPracticeFunctionComplimentDashboard",
        params: {
          isManager: isManager.value ? 1 : 0,
        },
      });
    })
    .catch(() => {
      loadingButton.value = false;
    });
};

const textarea = ref(null);

const adjustHeight = () => {
  if (textarea.value) {
    textarea.value.style.height = "auto";
    textarea.value.style.height = textarea.value.scrollHeight + "px";
    textarea.value.style.overflow = "hidden";
  }
};

watch(complimentDetails, () => {
  nextTick(() => {
    adjustHeight();
  });
});

onMounted(async () => {
  loading.value = true;
  if (isView) {
    adjustHeight();
    if (isManager.value) {
      await store
        .dispatch("maCompliments/fetchSingleComplimentMa", {
          complimentId: complimentId.value,
        })
        .then(() => {
          loading.value = false;
        });
    } else {
      await store
        .dispatch("hqCompliments/fetchSingleCompliment", {
          complimentId: complimentId.value,
        })
        .then(() => {
          loading.value = false;
        });
    }
  } else {
    await store.dispatch("hqCompliments/loadPractices").then((response) => {
      practices.value = response;
    });
  }
});
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 7px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}
::v-deep .vs__search {
  --vs-search-input-placeholder-color: rgb(148, 148, 148);
}
.swal2-cancel.swal2-styled {
  border-color: red !important;
}

::-webkit-calendar-picker-indicator {
  background-image: url("../../../assets/icons/calendar_month.svg");
}

input[type="date"]::-webkit-datetime-edit {
  display: none;
}

.isEdit {
  background: rgba(231, 243, 242, 0.65);
}
</style>
